#table-container {
    margin: 25px;
    padding-bottom: 50px;
}

#table-container h2 {
    padding: 50px 0 50px 0;
    font-family: 'Optimist', Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 300;
}


#table-container tr {
    padding-top: 20px;
    padding-bottom: 20px;
}
#table-container thead > tr > th {
    font-family: 'Interstate_Light', sans-serif, Arial;
    font-size: 1.5rem;
    font-weight: 200;
    color: #222;
}

#table-container thead > tr > th.filing-data {
    font-family: 'Interstate_Light', sans-serif, Arial;
    font-size: 1rem;
    font-weight: 200;
    color: #222;
}

#table-container tbody > tr > td, #table-container tbody > tr > td > a {
    text-decoration: none;
    color: rgb(140, 140, 144);
    font-family: 'Interstate_Light', sans-serif, Arial;
}

#table-container tbody > tr > td > a:hover {
    color: orange;
}

#table-container tbody > tr > td.company-name #table-container tbody > tr > td.filing-count  {
    padding-top: 25px;
    padding-bottom: 25px;
}

#table-container tbody > tr > td.accession-no #table-container tbody > tr > td.filing-date  {
    padding-top: 25px;
    padding-bottom: 25px;
}



