.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color:  rgb(6, 12, 15);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(219, 213, 213);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}


@media screen and (max-width) {
  .footer {
    height: 25px;
  }
  
}