.main-container {
    background: url('../images/img-1.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    opacity: 0.80;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    justify-content: center;
    object-fit: contain;
}

.main-container > h1 {
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 75px;
    margin-top: -100px;
}

.main-container > p {
    margin: 8px;
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    font-size: 32px;
    padding-top: 0.5em;
    /* padding-bottom: 0.5em; */

}

@media screen and (max-width:480px) {
    .main-container {
        background: none;
        background-color: rgba(132, 136, 138, 0.6);
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        object-fit: contain;
    }
    
    .main-container > h1 {
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 50px;
        margin-top: -100px;

    }
    
    .main-container > p {
        margin: 8px;
        color: white;
        font-family: 'Lato', sans-serif;
        font-weight: 100;
        font-size: 16px;
        padding-top: 0.5em;
    
    }
       
    
}